
import { defineComponent, onMounted, reactive, toRefs, computed } from 'vue'
import { ElMessageBox } from 'element-plus'
import { wuRequest } from '@/service'
import { url } from '@/service/config'

import { useRouter } from 'vue-router'
import objIsFalse from '@/comm/utils/obj-false'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: {},
    setup() {
        let t = reactive<any>({
            data: {},
            url,
            active: { active_1: true, active_2: false, active_3: false }
        })
        let router = useRouter()

        onMounted(async () => {
            // 保持菜单更新一直在顶
            document.body.scrollTop = document.documentElement.scrollTop = 0
            let res = await wuRequest.get({
                url: '/video'
            })
            if (res.data.code === 10001) {
                t.data = res.data.data.data
            } else {
                ElMessageBox.alert('数据请求错误，请稍后重试', '数据异常', {
                    confirmButtonText: 'OK'
                })
                return false
            }
        })
        const menuNumber = computed((): number => {
            if (t.active.active_1 == true) {
                return 1
            } else if (t.active.active_2 == true) {
                return 2
            } else if (t.active.active_3 == true) {
                return 3
            } else {
                return 1
            }
        })
        const linkTo = (video_url: string, isQn: boolean) => {
            if (isQn) {
                router.push({ path: '/video-desc', query: { video_url, isQn: 'yes' } })
            } else {
                router.push({ path: '/video-desc', query: { video_url } })
            }
        }
        const changMenu = (menu: string) => {
            objIsFalse(t.active, true, menu)
            t.active[menu] = true
        }
        return { ...toRefs(t), linkTo, changMenu, menuNumber }
    }
})
